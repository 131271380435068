<template>
  <v-fab icon app color="primary" dark @mouseenter="editMode = !editMode">
    <v-icon :icon="mdiDotsVertical" />
    <v-speed-dial
      v-model="editMode"
      transition="slide-y-reverse-transition"
      location="top center"
      target="parent"
    >
      <slot />
    </v-speed-dial>
  </v-fab>
</template>

<script lang="ts" setup>
import { mdiDotsVertical } from '@mdi/js'

const editMode = ref(false)
</script>
